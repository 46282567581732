import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Redirect, withRouter, Link } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PasswordStrengthBar from "react-password-strength-bar";
import { grey } from "@material-ui/core/colors";
import passwordValidator from 'password-validator';


import store from "../state/space";

import { Copyright } from "../components";

import loginService from "../service/login";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: grey[100],
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const ResetPassword = ({ match: { params } }) => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorConfirm, setErrorConfirm] = useState(false);
  const [errorTextConfirm, setErrorTextConfirm] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [expired, setExpired] = useState(false);
  const [success, setSuccess] = useState(false);

  const { id, token } = params;

  const submit = async () => {

    let schema = new passwordValidator();

    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits(2) // Must have at least 2 digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .is()
      .not()
      .oneOf(['Passw0rd', 'Password123']);

    const strongPassword = schema.validate(password);


    if (strongPassword === false) {
      setError(true);
      setErrorText(
        'Password must have minimum 8 characters, an uppercase letter, lowercase letter, atleast 2 digits and should not have spaces'
      )}

       else if ( password !== confirmPassword) {
        setError(false);
        setErrorText('');
        setErrorConfirm(true);
        setErrorTextConfirm("Passwords do not match.");
   }
      else {
     setErrorConfirm(false);
     setErrorTextConfirm(""); 
     setError(false);
     setErrorText('');

    const { id, token } = params;
    try {
      const res = await loginService.resetPassword({ id, token, password });
      if (res === "ok") {
        setSuccess(true);
      } else if (res.message === "invalid signature") {
        setExpired(true);
      }
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  }
  };

  const handleKeyPress = (e: any) => {
    //  check if both passwords exist
    toggleButton();
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || submit();
    }
  };

  const toggleButton = () => {
    if (password.length < 1 || confirmPassword < 1)
      return setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
  };

  if (expired)
    return <Redirect to={`/reset-password/${id}/${token}/expired`} />;
  if (success)
    return <Redirect to={`/reset-password/${id}/${token}/success`} />;

  return (
    <Container component="main" maxWidth="xs">
      {/* {store.isLoggedIn() ? <Redirect to="/" /> : null} */}
      <CssBaseline />
      <div className={classes.paper}>
        <Box mb={10}>
          <img src="/logo.svg" width="100%" />
        </Box>
        <LockOutlinedIcon color="primary" fontSize="large" />
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="Password"
            name="password"
            autoComplete="password"
            error={error}
            helperText={errorText}
            autoFocus
            onChange={(e) => {
              setPassword(e.target.value);
              toggleButton();
            }}
            onKeyUp={(e) => handleKeyPress(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            autoComplete="confirm-password"
            error={errorConfirm}
            helperText={errorTextConfirm}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              toggleButton();
            }}
            onKeyUp={(e) => handleKeyPress(e)}
          />
          <PasswordStrengthBar minLength={8} password={password} />
      
          <Button
            type="button"
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={() => submit()}
            disabled={isButtonDisabled}
          >
            Confirm
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withRouter(ResetPassword);
