import React, { useState } from 'react';
import { observer } from "mobx-react"

import store  from "../state/space"

function Main() {
  console.log(" store.session", store.session)
  return (
    <div className="App">
      main here
      <pre>
        { JSON.stringify(store.session, null, 2) }
      </pre>
      <pre>
        {JSON.stringify(store.session.getAccessToken(), null, 2)}
      </pre>
      <pre>
        {JSON.stringify(store.session.getRefreshToken(), null, 2)}
      </pre>
    </div>
  );
}

export default observer(Main);
