import { types, walk, flow } from 'mobx-state-tree';

import LoginService from '../service/login';
import data from '../data/data.json';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const Space = types
  .model({
    id: types.string,
    name: types.string,
    type: 'space',
    parent_id: types.maybeNull(types.string),
    spaces: types.array(types.late(() => Space)),
    things: types.array(types.late(() => Thing)),
  })
  .views((self) => ({
    get thingsCount() {
      return self.things.length;
    },
    get spacesCount() {
      return self.spaces.length;
    },
  }));

const Thing = types.model({
  thing: types.late(() => t),
});

const session = types
  .model({
    access_token: types.maybe(types.string),
    refresh_token: types.maybe(types.string),
  })
  .views((self) => {
    return {
      getAccessToken() {
        return parseJwt(self.access_token);
      },
      getRefreshToken() {
        return parseJwt(self.refresh_token);
      },
    };
  });

const t = types.model({
  id: types.string,
  name: types.string,
  type: 'thing',
  parent_id: types.maybeNull(types.string),
});

const sub = types.model({
  id: types.string,
  type: types.string,
});

const State = types
  .model({
    loggedIn: false,
    error: types.maybe(types.string),
    session,
    spaces: types.array(Space),
    subs: types.array(sub),
  })
  .actions((self) => {
    return {
      logIn: flow(function* (email, password) {
        self.error = undefined;
        try {
          const res = yield LoginService.login(email, password);
          console.log(typeof res);
          if (res.error) {
            throw res;
          }
          self.session = res;
          self.loggedIn = true;
        } catch(e) {
          console.log(e);
          if (e.error) {
            self.error = `Login Failed. ${e.message}.`;
          };
        };
      }),
      register: flow(function* (obj) {
        const res = yield LoginService.register(obj);
        self.session = res;
      }),
      setLoggedIn(value) {
        self.loggedIn = value;
      },
      addSub({ id, type }) {
        self.subs.push({
          id,
          type,
        });
      },
    };
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.loggedIn;
    },
  }));

const s = State.create({
  loggedIn: false,
  session: {},
  spaces: data.data.space_spaces,
  subs: [],
});

walk(s.spaces, (item) => {
  console.log('item', item);
});
export default s;
