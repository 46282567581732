import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import PasswordStrengthBar from 'react-password-strength-bar';
import { Redirect, withRouter, Link } from 'react-router-dom';
import store from "../state/space";

import { Copyright } from '../components';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: 'red'
  }
}));



const SetPassword = ({ match: { params }}) => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
  const handleSet = () => {
    // check if passwords are the same
    if (password != confirmPassword) return setError('Passwords do not match.')
    const { id } = params;
    console.log(id);
  }

  const handleKeyPress = e => {
    //  check if both passwords exist
    if (password.length < 1 || confirmPassword < 1) return setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handleSet();
    }
  };
  return (

    <Container component="main" maxWidth="xs">
      {store.isLoggedIn() ? <Redirect to="/" /> : null}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Set Password</Typography>
        <Typography variant="h6">Securely set a strong password</Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="Password"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={(e) => handleKeyPress(e)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            onKeyUp={(e) => handleKeyPress(e)}
          />
          <PasswordStrengthBar password={password} />
          <Typography varient="h7" className={classes.error}>
            {error}
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleSet()}
            disabled={isButtonDisabled}
          >
            Complete Verification  
          </Button>

        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default withRouter(SetPassword)