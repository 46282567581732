import React from 'react';
import { 
  Container, 
  Box,
  CssBaseline,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TimerOff from '@material-ui/icons/TimerOff';

import { Copyright } from '../components';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }, text: {
    textAlign: 'center'
  }
}));

const ResetLinkExpired = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box mb={10}>
          <img src="/logo.svg" width="100%"/>
        </Box>
        <TimerOff fontSize="large" color="primary"/>
        <Typography component="h1" variant="h6" className={classes.text}>Sorry, this password reset link has expired.</Typography>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
};

export default ResetLinkExpired;