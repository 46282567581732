import queryString from 'querystring';

class LocalStore {
  constructor(prefix = "token") {
    this.prefix = prefix
  }

  async storeToken(token) {
    return localStorage.setItem(this.prefix, JSON.stringify(token));
  }
  
  async getToken() {
    return JSON.parse(localStorage.getItem(this.prefix));
  }
  async removeToken() {
    return localStorage.removeItem(this.prefix);
  }
}

class LoginService {
  constructor({ store }) {
    this._store = store || new LocalStore();
  };

  async init() {
    const tokenSet = await this._store.getToken()
    console.log("token set", tokenSet)
  };

  async login( email, password ) {
    const res = await fetch('/login', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email, password
      }),
    })
    const json = await res.json()
    console.log(json)
    this._store.storeToken(json)
    return json;
  };

  async register(obj) {
    const res = await fetch('/register', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
    const json = await res.json()
    console.log(json)
    this._store.storeToken(json)
    return json;
  };

  async logOut() {
    // const params = await this.params("logOut")
  //  const res = await this._auth_request(`${this._url}/logout`, params)
    // if (res.ok) {
    //   this._store.removeToken()
    //   return true
    // }
    return false
  };

  async resetPassword(obj) {
    const res = await fetch('/password/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    if (res.ok) {
      return 'ok';
    } else {
      const json = await res.json()
      console.log(json)
      return json;
    }
  }

}
const ls = new LoginService({})
export default ls;